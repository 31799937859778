import {BasicsClass} from "@/common/BasicsClass";

export default class DataStatisticsAPI extends BasicsClass {
    public apiUrls: any = new Map([
        ['getStatHome', {
            url: '/stat/home',
            name: 'home',
            label: '今日营业额'
        }],
        // ['getStatIndex', {
        //     url: '/stat/index',
        //     name: 'index',
        //     label: '数据统计'
        // }]
    ])

    //今日营业额
    public statHome(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getStatHome').url
        this.BasicPost(
            url,
            {
                ...params,
            },
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }

    //数据统计
    public statindex(params: any, successCb: Function, failCb: Function, showLoading: boolean = false) {
        const apiUrls = this.apiUrls
        const url = apiUrls.get('getStatIndex').url
        this.BasicPost(
            url,
            {...params,},
            false,
            false,
            false,
            successCb,
            failCb,
            showLoading
        );
    }
}
