







































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import DataStatisticsAPI from "@/common/api/dataStatistics";
import { getPageAPIs } from "@/common/util";
export const pageApiList = getPageAPIs(DataStatisticsAPI);
@Component
export default class DataStatistics extends Vue {
  private DataStatisticsAPI: any = new DataStatisticsAPI();
  private activated(): void {
    this.getData()
  }
  private result:any = {}
  private getData(): void {
    this.DataStatisticsAPI.statHome(
        {},
        (res: any) => {
          console.log('今日营业',res)
          this.result = res.data
        }
    );
  }
}
